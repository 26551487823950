import React from 'react'
import { Container } from '@material-ui/core'

import { useUser } from '../../contexts'
import { LoginCard } from '..'

export const Auth: React.FC = ({ children }) => {
  const { decodedToken } = useUser()

  return decodedToken && Array.isArray(decodedToken.roles) && decodedToken.roles.includes('fed:1') ? (
    <>{children}</>
  ) : (
    <Container css={{ marginTop: 36 + 64, marginBottom: 36 }}>
      <LoginCard />
    </Container>
  )
}
