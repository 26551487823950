import React, { useEffect, useState } from 'react'
import { Typography, Paper, Avatar } from '@material-ui/core'
import { yellow, blue } from '@material-ui/core/colors'
import { Tournament } from 'y2c-helpers'

import { Contender } from '..'
import { useMemdbRequest } from '../../../hooks'

interface GroupResponse {
  contenders: {
    partid: number
    name: string
    result: {
      place: number
    }
  }[]
}

export const TournamentGroup: React.FC<{ group: Tournament['classes'][0]['groups'][0]; contenders?: Contender[] }> = ({
  group: { id: groupId },
  contenders
}) => {
  const { request } = useMemdbRequest()
  const [group, setGroup] = useState<GroupResponse>()

  useEffect(() => {
    request<GroupResponse>({
      url: `/tournament/group/${groupId}`,
      params: {
        schema: {
          contenders: [
            {
              partid: 1,
              name: 1,
              result: {
                place: 1
              }
            }
          ]
        }
      }
    }).then(response => {
      if (response?.data) {
        // eslint-disable-next-line no-bitwise
        setGroup({ ...response.data, contenders: response.data.contenders.sort((a, b) => (+(a.result.place > b.result.place) << 1) - 1) })
      }
    })
  }, [groupId])

  return (
    <>
      {group?.contenders.map((cont, index) => {
        const contender = contenders?.find(({ team }) => team.id === cont.partid)
        return (
          <Paper
            key={cont.partid}
            css={{ position: 'relative', overflow: 'hidden', padding: 8, margin: '8px 0', display: 'flex', alignItems: 'center' }}
          >
            <Avatar aria-label="place" variant="rounded" css={{ marginRight: 8, width: 28, height: 28, fontSize: '1rem' }}>
              {cont.result.place || index + 1}
            </Avatar>
            <div>
              <Typography variant="body2">{contender?.team.name || cont.name}</Typography>
              {contender && (
                <Typography variant="body2" color="textSecondary" css={{ fontSize: 10 }}>
                  {contender.team.school.name}
                </Typography>
              )}
            </div>
            {contender && (
              <div css={{ position: 'absolute', right: 0, bottom: 0, display: 'flex' }}>
                <Avatar aria-label="springbreak" variant="square" css={{ width: 16, height: 16, fontSize: 10, backgroundColor: blue[700] }}>
                  {contender.team.school.springbreak}
                </Avatar>
                <Avatar
                  aria-label="easterbreak"
                  variant="square"
                  css={{ width: 16, height: 16, fontSize: 10, backgroundColor: yellow[700] }}
                >
                  {contender.team.school.easterbreak}
                </Avatar>
              </div>
            )}
          </Paper>
        )
      })}
    </>
  )
}
