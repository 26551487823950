import React from 'react'
import { TableCellProps } from '@material-ui/core/TableCell'
import { ApiStatus } from 'y2c-helpers'

import { ClassNames, Interpolation } from '@emotion/core'
import { Cell } from '..'

export interface Sort {
  path: keyof (ApiStatus & { district: string })
  direction: 'asc' | 'desc'
}

export const AdminCellSort: React.FC<
  TableCellProps & { css?: Interpolation; path: Sort['path']; sortArray: Sort[]; onSort(sortArray: Sort[]): void }
> = ({ path, sortArray, onSort, children, css, ...props }) => {
  const sort = sortArray.find(_sort => _sort.path === path)

  return (
    <ClassNames>
      {({ cx, css: cssFn }) => (
        <Cell
          css={cx(cssFn({ cursor: 'pointer' }), cssFn(css))}
          {...props}
          onClick={() => {
            if (sort) {
              const newArray = sortArray.filter(_sort => _sort !== sort)
              if (sort.direction === 'asc') {
                // onSort([...newArray, { path, direction: 'desc' }])
                onSort([{ path, direction: 'desc' }])
              } else {
                onSort(newArray)
              }
            } else {
              // onSort([...sortArray, { path, direction: 'asc' }])
              onSort([{ path, direction: 'asc' }])
            }
          }}
        >
          {children}
          {sort && (sort.direction === 'asc' ? '^' : 'v')}
        </Cell>
      )}
    </ClassNames>
  )
}
