import React, { useState } from 'react'
import {
  TableRow,
  Tooltip,
  IconButton,
  useTheme,
  Theme,
  Popover,
  TextField,
  Button,
  SvgIcon,
  List,
  ListItem,
  ListItemText,
  Checkbox
} from '@material-ui/core'
import { Add, Edit, Refresh, Close } from '@material-ui/icons'
import { keyframes } from '@emotion/core'
import { ApiStatus } from 'y2c-helpers'

import { EmailMinus } from 'mdi-material-ui'
import { TableRowProps } from '@material-ui/core/TableRow'
import { getColor } from '../..'
import { Cell, statusMap } from '..'
import { useY2CRequest } from '../../../hooks'
import { useWp } from '../../../contexts'

export const Row: React.FC<
  TableRowProps & { index: number; status: ApiStatus & { district: string }; onRefresh(status: ApiStatus): void }
> = ({ index, status, onRefresh, ...props }) => {
  const { palette } = useTheme<Theme>()
  const [editEl, setEditEl] = useState<Element>()
  const [emailsEl, setEmailsEl] = useState<Element>()
  const { spacing } = useTheme<Theme>()
  const { request: y2cRequest, isLoading: isY2CLoading } = useY2CRequest()
  const { tournament } = useWp()
  const created = new Date(status.created)
  const updated = new Date(status.updated)

  const refreshRow = async () => {
    const response = await y2cRequest<Omit<ApiStatus, 'user' | 'tournament'>>({
      method: 'POST',
      url: '/status/refresh',
      data: { user: status.user, tournament: status.tournament, tournaments: tournament.acf.tournamentid.split(',') }
    })

    if (response) {
      onRefresh({ ...status, ...response.data.result })
    }
  }

  const handleToggleEmails = ({ currentTarget }: React.MouseEvent<Element>) => {
    setEmailsEl(emailsEl ? undefined : currentTarget)
  }

  const handleToggleEdit = ({ currentTarget }: React.MouseEvent<Element>) => {
    setEditEl(editEl ? undefined : currentTarget)
  }

  const handleEmailSubscribeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailsEl(undefined)
    const emailSubscribe = event.currentTarget.checked ? 'subscribed' : 'unsubscribed'
    const data: Pick<ApiStatus, 'user' | 'tournament' | 'emailSubscribe'> = {
      user: status.user,
      tournament: status.tournament,
      emailSubscribe
    }

    y2cRequest({
      method: 'POST',
      url: '/status',
      data
    }).then(response => {
      if (response) {
        onRefresh({ ...status, emailSubscribe })
      }
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement & { comment: HTMLInputElement }>) => {
    event.preventDefault()
    setEditEl(undefined)
    const comment = event.currentTarget.comment.value
    const data: Pick<ApiStatus, 'user' | 'tournament' | 'comment'> = { user: status.user, tournament: status.tournament, comment }

    y2cRequest({
      method: 'POST',
      url: '/status',
      data
    }).then(response => {
      if (response) {
        onRefresh({ ...status, comment })
      }
    })
  }

  const handleAdd = () => {
    const data: Pick<ApiStatus, 'user' | 'tournament' | 'removed'> = { user: status.user, tournament: status.tournament, removed: false }
    y2cRequest({
      method: 'POST',
      url: '/status',
      data
    }).then(response => {
      if (response) {
        onRefresh({ ...status, removed: false })
      }
    })
  }
  const handleRemove = () => {
    const data: Pick<ApiStatus, 'user' | 'tournament' | 'removed'> = { user: status.user, tournament: status.tournament, removed: true }
    y2cRequest({
      method: 'POST',
      url: '/status',
      data
    }).then(response => {
      if (response) {
        onRefresh({ ...status, removed: true })
      }
    })
  }

  return (
    <TableRow {...props}>
      <Cell size="small" css={{ textAlign: 'right' }}>
        {index}
      </Cell>
      <Cell size="small">
        <a href={`${process.env.GATSBY_MEMDB_URL}/ViewMemberServlet?memberid=${status.user}`}>{status.name || status.user}</a>
      </Cell>
      <Cell>{status.school}</Cell>
      <Cell>{status.district}</Cell>
      <Cell align="center" css={{ borderRight: '1px solid rgba(0,0,0,.12)' }}>
        {status.nTeams || 0}/{status.nClubs || 0}
      </Cell>
      {statusMap.map(key => (
        <Cell key={key} align="center" css={{ paddingLeft: spacing(1), paddingRight: spacing(1) }}>
          <div
            css={{
              display: 'inline-block',
              width: 14,
              height: 14,
              borderRadius: '50%',
              backgroundColor: getColor(status.status[key]),
              marginBottom: -2
            }}
          />
        </Cell>
      ))}
      <Cell align="center" css={{ borderLeft: '1px solid rgba(0,0,0,.12)', padding: 0 }}>
        <Tooltip title={status.created} placement="top">
          <span>
            {`0${created.getDate()}`.slice(-2)}/{`0${created.getMonth() + 1}`.slice(-2)}
          </span>
        </Tooltip>
      </Cell>
      <Cell align="center" css={{ padding: 0 }}>
        <Tooltip title={status.updated} placement="top">
          <span>
            {`0${updated.getDate()}`.slice(-2)}/{`0${updated.getMonth() + 1}`.slice(-2)}
          </span>
        </Tooltip>
      </Cell>
      <Cell align="center" css={{ padding: 0 }}>
        <IconButton size="small" css={{ margin: -6, position: 'relative' }} onClick={handleToggleEmails}>
          {status.emailSubscribe === 'opted_in' || status.emailSubscribe === 'subscribed' ? (
            <>
              <SvgIcon>
                {/* eslint-disable-next-line max-len */}
                <path d="m3,4c-1.11,0 -2,0.89 -2,2l0,12a2,2 0 0 0 2,2l11.09,0c-0.06,-0.33 -0.09,-0.66 -0.09,-1a6,6 0 0 1 6,-6c0.34,0 0.67,0.03 1,0.09l0,-7.09c0,-1.11 -0.9,-2 -2,-2l-16,0m0,2l8,5l8,-5l0,2l-8,5l-8,-5l0,-2" />
              </SvgIcon>
              <div
                css={{
                  position: 'absolute',
                  right: 1,
                  bottom: 2,
                  minWidth: 12,
                  fontSize: 8,
                  fontWeight: 'bold',
                  lineHeight: '12px',
                  borderRadius: 6
                }}
              >
                {status.emails ? status.emails.length : 0}
              </div>
            </>
          ) : (
            <EmailMinus />
          )}
        </IconButton>
        <Popover anchorEl={emailsEl} open={!!emailsEl} onClose={handleToggleEmails}>
          <List>
            {(status.emails || []).map(email => (
              <ListItem key={email.messageId} css={{ '> div': { whiteSpace: 'nowrap', margin: '0 4px' } }}>
                <ListItemText>{email.category}</ListItemText>
                <ListItemText>{new Date(email.timestamp).toLocaleString('sv-SE')}</ListItemText>
                <ListItemText>{email.events.length && email.events[email.events.length - 1].event}</ListItemText>
              </ListItem>
            ))}
            <ListItem>
              <Checkbox
                defaultChecked={status.emailSubscribe === 'opted_in' || status.emailSubscribe === 'subscribed'}
                onChange={handleEmailSubscribeChange}
              />
              <ListItemText>Får automatiska email</ListItemText>
            </ListItem>
          </List>
        </Popover>
      </Cell>
      <Cell align="center" css={{ padding: 0 }}>
        <IconButton size="small" css={{ margin: -6 }} onClick={handleToggleEdit}>
          <Edit color={status.comment ? 'action' : 'disabled'} />
        </IconButton>
        <Popover anchorEl={editEl} open={!!editEl} onClose={handleToggleEdit}>
          <form
            css={{
              margin: `0 ${spacing(2)}px ${spacing(2)}px`,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 600
            }}
            onSubmit={handleSubmit}
          >
            <TextField multiline defaultValue={status.comment} margin="normal" label="Notering" name="comment" />
            <div>
              <Button variant="contained" color="primary" type="submit">
                Spara
              </Button>
            </div>
          </form>
        </Popover>
      </Cell>
      <Cell align="center" css={{ padding: 0 }}>
        <IconButton
          size="small"
          css={[
            { margin: -6 },
            isY2CLoading && {
              animation: `${keyframes({
                from: {
                  transform: 'rotate(0deg)'
                },
                to: {
                  transform: 'rotate(360deg)'
                }
              })} 1s ease infinite`
            }
          ]}
          onClick={refreshRow}
        >
          <Refresh color={isY2CLoading ? 'primary' : 'inherit'} />
        </IconButton>
      </Cell>
      <Cell align="center" css={{ padding: 0 }}>
        {status.removed ? (
          <IconButton size="small" onClick={handleAdd}>
            <Add css={{ color: palette.text.disabled, ':hover': { color: palette.text.primary } }} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={handleRemove}>
            <Close />
          </IconButton>
        )}
      </Cell>
    </TableRow>
  )
}
