import React, { useState, useEffect, useMemo } from 'react'
import { School, Gavel, People, VerifiedUser } from '@material-ui/icons'
import { FileDocumentBox } from 'mdi-material-ui'
import { ApiStatus, playingYear } from 'y2c-helpers'

import { Card, Container, Table, TableHead, TableRow, useTheme, Theme, Tooltip, TextField, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { useDebounce } from 'use-debounce/lib'

import { Loading } from '../..'
import { Cell, AdminBody, AdminCellSort, Sort } from '..'
import { useY2CRequest, useMemdbRequest } from '../../../hooks'
import { useWp, useUser } from '../../../contexts'

export const statusMap: (keyof ApiStatus['status'])[] = ['school', 'clubs', 'teams', 'documents', 'verifications']

import { Auth } from '..'

export const Reg = () => {
  const { token } = useUser()
  const { request: y2cRequest, isLoading: isY2CLoading } = useY2CRequest()
  const { request: memdbRequest } = useMemdbRequest()
  const [statuses, setStatuses] = useState<(ApiStatus & { district: string })[]>([])
  const [sortArray, setSortArray] = useState<Sort[]>([])
  const [filterString, setFilterString] = useState('')
  const [showRemoved, setRemoved] = useState(false)
  const [filterStringDebounced] = useDebounce(filterString, 200)

  const { spacing } = useTheme<Theme>()

  const { category } = useWp()

  useEffect(() => {
    if (token) {
      Promise.all([
        y2cRequest<ApiStatus[]>({
          url: `/status/${category.slug.toUpperCase()}-${playingYear}`
        }),
        memdbRequest<{ districts: { id: number; name: string }[] }>({
          url: '/federation/1',
          params: {
            schema: {
              districts: [
                {
                  id: 1,
                  name: 1
                }
              ]
            }
          }
        })
      ]).then(([statusResponse, districtsResponse]) => {
        if (statusResponse?.data && districtsResponse?.data) {
          const districts: { [key: number]: string } = districtsResponse.data.districts.reduce(
            (obj, { id, name }) => ({ ...obj, [id]: name }),
            {}
          )
          setStatuses(
            statusResponse.data.result.map(status => {
              return { ...status, district: districts[status.districtid || 0] }
            })
          )
        }
      })
    }
  }, [token])

  const filteredStatuses = useMemo<(ApiStatus & { district: string })[]>(() => {
    const regEx = new RegExp(filterStringDebounced)

    return (
      statuses.filter(
        status =>
          (showRemoved || !status.removed) && (!filterStringDebounced ||
            (
              (status.name && status.name.match(regEx)) ||
              (status.school && status.school.match(regEx)) ||
              (status.district && status.district.match(regEx)) ||
              status.updated.match(regEx)
            ))
      ).sort((a, b) =>
        sortArray.reverse().some(sort => (sort.direction === 'asc') !== (a[sort.path] || '') < (b[sort.path] || '')) ? 1 : -1
      )
    )
  }, [statuses, filterStringDebounced, sortArray, showRemoved])

  const handleRefresh = (index: number) => (status: ApiStatus) => {
    const newStatus = { ...filteredStatuses[index], ...status }
    setStatuses([...statuses.filter(({user}) => user !== newStatus.user), newStatus])
  }

  const handleFilterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterString(e.currentTarget.value)
  }

  return (
  <Auth>
    <Container css={{ marginTop: 36 + 64, marginBottom: 36 }}>
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox

            color="primary"
              checked={showRemoved}
              onChange={() => { setRemoved(!showRemoved) }} value="showRemoved"
            />
          }
          label="Visa borttagna"
        />
      </FormGroup>
      <TextField
        label="Filter"
        value={filterString}
        onChange={handleFilterChanged}
        css={{ marginBottom: 8, width: '100%', maxWidth: 400 }}
      />
      <Card css={{ overflow: 'auto', position: 'relative' }}>
        {isY2CLoading ? (
          <div css={{ minHeight: 200 }}>
            <Loading />
          </div>
        ) : (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <Cell css={{ textAlign: 'right' }}>#</Cell>
                  <AdminCellSort
                    path="name"
                    sortArray={sortArray}
                    onSort={newSortArray => {
                      setSortArray(newSortArray)
                    }}
                  >
                    Namn
            </AdminCellSort>
                  <AdminCellSort
                    path="school"
                    sortArray={sortArray}
                    onSort={newSortArray => {
                      setSortArray(newSortArray)
                    }}
                  >
                    Skola
            </AdminCellSort>
                  <AdminCellSort
                    path="district"
                    sortArray={sortArray}
                    onSort={newSortArray => {
                      setSortArray(newSortArray)
                    }}
                  >
                    Distrikt
            </AdminCellSort>
                  <Cell align="center" css={{ borderRight: '1px solid rgba(0,0,0,.12)' }}>
                    <Tooltip title="Lag / Klubbar">
                      <span>L/K</span>
                    </Tooltip>
                  </Cell>
                  {statusMap.map(key => (
                    <Cell
                      key={key}
                      align="center"
                      css={{
                        minWidth: 24,
                        maxWidth: 24,
                        paddingLeft: spacing(1),
                        paddingRight: spacing(1),
                        '> *': { display: 'block', margin: '0 auto' }
                      }}
                    >
                      {key === 'school' ? (
                        <School />
                      ) : key === 'clubs' ? (
                        <Gavel />
                      ) : key === 'teams' ? (
                        <People />
                      ) : key === 'documents' ? (
                        <FileDocumentBox />
                      ) : (
                                <VerifiedUser />
                              )}
                    </Cell>
                  ))}
                  <AdminCellSort
                    align="center"
                    css={{ borderLeft: '1px solid rgba(0,0,0,.12)', padding: '0 4px' }}
                    path="created"
                    sortArray={sortArray}
                    onSort={newSortArray => {
                      setSortArray(newSortArray)
                    }}
                  >
                    <Tooltip title="Skapad" placement="top">
                      <span>Skapad</span>
                    </Tooltip>
                  </AdminCellSort>
                  <AdminCellSort
                    align="center"
                    css={{ padding: '0 4px' }}
                    path="updated"
                    sortArray={sortArray}
                    onSort={newSortArray => {
                      setSortArray(newSortArray)
                    }}
                  >
                    <Tooltip title="Uppdaterad" placement="top">
                      <span>Uppdat.</span>
                    </Tooltip>
                  </AdminCellSort>
                  <Cell />
                  <Cell />
                  <Cell />
                  <Cell />
                </TableRow>
              </TableHead>

              <AdminBody statuses={filteredStatuses} onRefresh={handleRefresh} />
            </Table>
          )}
      </Card>
    </>
    </Container>
  </Auth>
)}
