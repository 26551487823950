import React, { useEffect, useState } from 'react'
import { Container, Button, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'

import { Auth, TournamentClass, AddStep } from '..'
import { CascadingMenu, MenuItem } from '../..'
import { useTournaments } from '../../../contexts'
import { useMemdbRequest } from '../../../hooks'

export interface Contender {
  team: {
    id: number
    name: string
    school: {
      id: number
      name: string
      breakyear: number
      springbreak: number
      easterbreak: number
    }
  }
}

export const Tournament: React.FC<{ tournamentId: number }> = ({ tournamentId }) => {
  const { tournaments, isLoading } = useTournaments()

  const [contenders, setContenders] = useState<Contender[]>()
  const { request: memdbContendersRequest } = useMemdbRequest()

  const tournament = tournaments?.find(_tournament => _tournament.id === tournamentId)

  const tournamentMenu: MenuItem = {
    title: tournaments?.find(_tournament => _tournament.id === tournamentId)?.name,
    subMenu: tournaments?.map(_tournament => ({
      title: _tournament.name,
      link: `/admin/turneringar?turnering=${_tournament.id}`
    })),
    Component: Button,
    menuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      }
    }
  }

  useEffect(() => {
    if (tournaments && !tournamentId) {
      navigate(`${window.location.pathname}?turnering=${tournaments[0].id}`, {
        replace: true
      })
    }
  }, [tournaments, tournamentId])

  useEffect(() => {
    const group = tournament?.classes.reverse()[0]?.groups[0]
    if (group) {
      memdbContendersRequest<{ contenders: Contender[] }>({
        url: `/tournament/group/${group.id}`,
        params: {
          schema: {
            contenders: [
              {
                team: {
                  id: 1,
                  name: 1,
                  school: {
                    id: 1,
                    name: 1,
                    breakyear: 1,
                    springbreak: 1,
                    easterbreak: 1
                  }
                }
              }
            ]
          }
        }
      }).then(response => {
        if (response?.data) {
          setContenders(response.data.contenders)
        }
      })
    }
  }, [tournament])

  return (
    <Auth>
      <Container css={{ marginTop: 36 + 64, marginBottom: 36 }}>
        <Grid container spacing={3}>
          {tournament?.classes.reverse().map((clazz, index) => (
            <Grid item xs={3} key={clazz.id}>
              <TournamentClass clazz={clazz} head={index === 0 && <CascadingMenu menu={tournamentMenu} />} contenders={contenders} />
            </Grid>
          ))}

          {tournament && tournament.classes.length < 4 && (
            <Grid item xs={3}>
              <AddStep />
            </Grid>
          )}
        </Grid>
      </Container>
    </Auth>
  )
}
