import React from 'react'
import { Button, Typography } from '@material-ui/core'
import styled, { CreateStyled } from '@emotion/styled'
import { Tournament } from 'y2c-helpers'

import { TournamentGroup, Contender } from '..'
import { Theme } from '../..'

const GroupWrapper = (styled as CreateStyled<Theme>).div(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  padding: '8px 8px 0',
  marginBottom: 8
}))

export const TournamentClass: React.FC<{ clazz: Tournament['classes'][0]; head: React.ReactNode; contenders?: Contender[] }> = ({
  clazz,
  head,
  contenders
}) => (
  <>
    {head || <Button>{clazz.name}</Button>}
    {clazz.groups.length === 1 ? (
      <TournamentGroup group={clazz.groups[0]} contenders={contenders} />
    ) : (
      clazz.groups.map(group => (
        <GroupWrapper key={group.id}>
          <Typography variant="subtitle1">{group.name}</Typography>
          <TournamentGroup group={group} contenders={contenders} />
        </GroupWrapper>
      ))
    )}
  </>
)
