import React from 'react'
import { Container } from '@material-ui/core'
import { Link } from 'gatsby'

import { Auth } from '.'

export const Admin: React.FC = () => (
  <Auth>
    <Container css={{ marginTop: 36 + 64, marginBottom: 36 }}>
      <div>
        <Link to="admin/anmalan">Anmälan / Registrering</Link>
      </div>
      {/* <div>
        <Link to="admin/turneringar">Turneringen / Lottning</Link>
      </div> */}
    </Container>
  </Auth>
)
