import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

export const AddStep = () => {
  const [showDialog, setShowDialog] = useState(false)

  function toggleDialog() {
    setShowDialog(!showDialog)
  }

  return (
    <>
      <Button onClick={toggleDialog}>+ Lägg till nytt steg</Button>
      <Dialog open={showDialog} onClose={toggleDialog}>
        <DialogTitle>Nytt steg</DialogTitle>
        <DialogContent>test</DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>Stäng</Button>
          <Button>Skapa</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
