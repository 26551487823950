import React from 'react'

import { Tournament, Page } from '../../lib/createPages'
import { Root, Header } from '../../components'
import { WpContextProvider } from '../../contexts'
import { Reg } from '../../components/admin'

interface AdminTemplateProps {
  pageContext: { tournament: Tournament; edges: { node: Page }[] }
}

const AdminRegTemplate: React.FC<AdminTemplateProps> = ({ pageContext }) => (
  <WpContextProvider tournament={pageContext.tournament}>
    <Root pageContext={pageContext}>
      <Header pageContext={pageContext}></Header>
      <Reg />
    </Root>
  </WpContextProvider>
)

export default AdminRegTemplate
